.ids-checkbox.StandaloneCheckbox {
  &:not(.is-centered) {
    .check-icon-wrapper,
    .label-content-wrapper:before {
      top: 0;
    }
  }

  label {
    padding-top: 0;
    padding-bottom: 0;
  }
}
