@mixin from-mq-xs {
  @media (min-width: 576px) {
    @content;
  }
}

@mixin until-mq-sm {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin from-mq-sm {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin until-mq-md {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin from-mq-md {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin from-mq-lg {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin from-mq-xl {
  @media (min-width: 1920px) {
    @content;
  }
}
