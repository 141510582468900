.ModuleDuration {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  column-gap: 1rem;

  @include until-mq-sm {
    flex-direction: column;
  }

  li {
    display: flex;
    align-items: center;
    white-space: nowrap;
    column-gap: 0.5rem;
  }
}
