.ModuleModal {
  ids-button {
    button {
      vertical-align: top;
    }
  }

  &:not(.is-fully-scrollable) {
    .modal-body {
      @include from-mq-sm {
        width: 750px; //to be tokenized
      }

      ids-scrollbar > div {
        height: 100%;
      }
    }
  }

  .ModuleModalHeader {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    @include from-mq-sm {
      align-items: center;
    }
  }

  .ModuleModalMain {
    .ModuleModalSection {
      margin-bottom: 2.5rem;

      .ModuleModalSectionHeading {
        margin-bottom: 1rem;

        @include from-mq-sm {
          margin-bottom: 2.5rem;
        }
      }
    }

    .Intro {
      .IntroProgress {
        margin-bottom: 2.5rem;
      }
    }

    .Outcomes {
      .OutcomesHeading {
        margin-bottom: 1.25rem;
      }
    }

    .OutcomesGrid,
    .LessonsGrid {
      display: grid;
      gap: 1.5rem;

      .ids-card {
        border-radius: var(--vfq-card-wrapper-border-radius-md);

        div {
          display: flex;
          column-gap: 0.5rem;
        }
      }
    }

    .OutcomesGrid {
      .ids-card {
        background-color: var(--vfq-card-wrapper-background-color-light-gray);
      }
    }

    .LessonsGrid {
      @include from-mq-sm {
        grid-template-columns: repeat(2, auto);
      }
    }
  }

  .ModuleModalFooter {
    display: flex;
    justify-content: flex-end;
  }
}
