.SignIn {
  height: 100dvh;

  .SignInWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .SignInMain {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      padding-block: 1.5rem 3.25rem;
    }
  }
}
