@import '/public/styles/core';

.LogoHeader {
  --position-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--position-top);
  row-gap: 1.5rem;

  @include from-mq-sm {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    row-gap: 0;
  }

  @include from-mq-md {
    --position-top: 3.25rem;
  }

  .LogoHeaderLogo {
    svg {
      --logo-height: 2.5rem;
      display: block;
      width: auto;
      height: var(--logo-height);

      @include from-mq-md {
        --logo-height: 3rem;
      }
    }
  }

  .LogoHeaderCta {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    ids-button button {
      display: flex;
    }
  }
}
