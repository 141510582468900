.DropdownPillFieldWrapper {
  .ids-label {
    padding-left: var(--vfq-dropdown-pill-label-padding-left);
    padding-right: var(--vfq-dropdown-pill-label-padding-right);
  }
}

.TextInputPillFieldWrapper {
  .ids-label {
    padding-left: var(--vfq-input-pill-label-padding-left);
    padding-right: var(--vfq-input-pill-label-padding-right);
  }
}
