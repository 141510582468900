.ids-modal {
  .modal-overlay {
    backdrop-filter: blur(10px);
  }

  .modal-body {
    .ModalHeaderWithSubtitle {
      display: flex;
      flex-direction: column;
      row-gap: 0.25rem;
    }
  }

  &.is-fully-scrollable .modal-body {
    @include until-mq-sm {
      width: calc(100dvw - 2rem); // to be removed when fixed on ids
    }
  }
}
